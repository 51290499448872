<template>
  <div>
    <v-row v-for="item in caracteristicas" :key="item.id">
      <v-col class="d-flex justify-center" cols="12" xs="12" sm="1" md="1">
        <FormButton icon type="perigo" @click="remove(item.id)"
          ><v-icon size="18">fas fa-times</v-icon></FormButton
        >
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="5">
        <FormAutocomplete
          label="Característica"
          v-model="item.idcaracteristica"
          :options="options"
          :validation="getValidationField(item.id, 'idcaracteristica')"
          class="form-inline-item"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6">
        <FormField
          class="form-inline-item"
          label="Descrição"
          :validation="getValidationField(item.id, 'descricao')"
          v-model="item.descricao"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <FormButton type="primario" @click="add" text="Nova característica" />
    </v-row>
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { mapGetters } from 'vuex'
import { CARACTERISTICA_PRODUTO_LIST } from '@/store/actions/caracteristicaProduto'
import * as _ from 'lodash'
export default {
  name: 'ProdutoFormCaracteristica',
  components: { FormAutocomplete, FormField, FormButton },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    caracteristicas: [
      {
        idcaracteristica: '',
        descricao: '',
        id: Math.random() * 10000
      }
    ]
  }),
  computed: {
    ...mapGetters(['getListCaracteristicas']),
    options() {
      return this.getListCaracteristicas.map(item => ({
        text: item.nome,
        value: item.idcaracteristica
      }))
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.caracteristicas = newVal
      }
    }
  },
  created() {
    this.$store.dispatch(CARACTERISTICA_PRODUTO_LIST)
    if (this.value) {
      this.caracteristicas = this.value
    }
  },
  methods: {
    add() {
      this.caracteristicas.push({
        idcaracteristica: '',
        descricao: '',
        id: Math.random() * 10000
      })
    },
    remove(id) {
      this.caracteristicas = this.caracteristicas.filter(item => item.id != id)
      this.onChange()
    },
    onChange() {
      this.$emit('input', this.caracteristicas)
      this.$emit('change', this.caracteristicas)
    },
    getValidationField(id, columns) {
      if (this.validation && this.validation.$each) {
        for (const i in this.validation.$each.$iter) {
          let val = this.validation.$each.$iter[i]
          if (_.get(val, '$model.id', '') == id) {
            return _.get(val, columns, null)
          }
        }
      }
      return null
    }
  }
}
</script>
