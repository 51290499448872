<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/produto"
  >
    <LineDivider><h4>Dados básicos</h4></LineDivider>
    <FormField
      label="Código de barras"
      placeholder="(opcional)"
      v-model="form.codigobarras"
      :disabled="disabledCodBarras"
      :validation="$v.form.codigobarras"
      @blur="pesquisarCodigoBarrasExistente"
      hint="(identificador da indústria na embalagem do produto)"
    />
    <FormField
      label="Nome"
      v-model="form.descricao"
      :validation="$v.form.descricao"
    />
    <p>Nomes / Termos alternativos</p>
    <small>
      Nomes ou palavras alternativas desse produto. Utilizado primariamente para
      sugerir pesquisas.
    </small>
    <div class="lista-nomes-alternativos">
      <v-container fluid style="padding: 0px">
        <v-row
          dense
          no-gutters
          v-for="(k, desc) in form.descricoes_adicionais"
          :key="desc"
        >
          <v-col>
            <FormField
              :dense="true"
              :label="'alternativa ' + (Number(desc) + 1)"
              :value="k"
              @change="val => handleEditNomeAlternativo(desc, val)"
            />
          </v-col>
          <v-col>
            <FormButton
              small
              type="auxiliar"
              text="x"
              @click="() => handleRemoverNomeAlternativo(desc)"
            />
          </v-col>
        </v-row>
        <v-row dense no-gutters>
          <v-col>
            <FormButton
              text="Adicionar"
              @click="() => form.descricoes_adicionais.push('')"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <FormField
      label="Código interno"
      v-model="form.id_produto_erp"
      :validation="$v.form.id_produto_erp"
      hint="(código do produto utilizado no seu sistema de emissor de nota fiscal)"
    />

    <FormTags
      :placeholder="'Pesquise e selecione o produto principal'"
      :label="'Produto principal'"
      v-model="produto_principal"
      :endpoint="endpointProdutos"
      queryname="filter"
      :searchWithSpaces="true"
      keyid="id_produto_erp"
      keyvalue="text"
      :multiple="false"
      :clearable="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.nome }}</p>
          <small v-if="item.id_produto_erp"
            >Cód. interno: {{ item.id_produto_erp }}</small
          >
          <small v-if="item.codigobarras"
            >Cód. barras:{{ item.codigobarras }}</small
          >
        </div>
      </template>
    </FormTags>

    <!-- <FormSwitch v-model="form.ativo" label="Exibir estando indisponível" v-show="false"/> -->
    <p>Aceita venda fracionada?</p>
    <small>
      Para modificar de quanto em quanto seu cliente adiciona o item no
      carrinho, utilize o campo de granularidade. Para produtos vendidos de 0,5
      em 0,5, configure esse valor na granularidade
    </small>
    <p></p>
    <FormField
      label="Granularidade"
      v-model="form.granularidade"
      :money="moneyMask"
      help="Indique de quanto se compõe a fração dos produtos. O padrão é 0,2 para produtos fracionados e 1 para produtos unitários"
    />
    <FormSwitch
      v-model="form.permitir_alteracao_campos_erp"
      label="Permitir que a integração modifique a granularidade"
      val="granularidade"
    />
    <FormField
      label="Unidade de venda múltiplos"
      v-model="form.label_unidade_multiplo"
      help="Informe qual a menor unidade de venda possível do produto. Exemplo: UN (se for unidade) CX (se for caixa), FD (se for fardo) etc."
    />
    <FormField
      label="Unidade de venda"
      v-model="form.label_unidade"
      help="Informe qual a maior unidade de venda possível do produto. Exemplo: UN (se for unidade) CX (se for caixa), FD (se for fardo) etc."
    />
    <FormField
      label="Múltiplo do produto"
      v-model="form.multiplo_incremento"
      :money="moneyMask"
      help="Informe qual quantidade desse produto deverá ser adicionada quando seu cliente clicar + (adicionar item ao carrinho)."
    />
    <v-row no-gutters>
      <v-col cols="12"><b>Tipo de venda</b></v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="auto" class="mr-lg-5"
        ><FormCheckbox
          label="Avulso"
          val="AVULSO"
          v-model="form.tag"
          :disabled="form.tag.includes('SOB_DEMANDA')"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="4" lg="auto" class="mr-lg-5"
        ><FormCheckbox
          label="Recorrência"
          val="RECORRENCIA"
          v-model="form.tag"
          :disabled="form.tag.includes('SOB_DEMANDA')"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="auto"
        class="mr-lg-5"
      >
        <v-row no-gutters>
          <FormCheckbox
            label="Sob Demanda"
            val="SOB_DEMANDA"
            v-model="form.tag"
            :disabled="
              form.tag.includes('AVULSO') ||
                form.tag.includes('RECORRENCIA') ||
                (!enableVendaMais && !form.tag.includes('SOB_DEMANDA'))
            "
          />
          <v-tooltip top v-if="!enableVendaMais">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" class="ml-3 mt-n2"
                >far fa-question-circle</v-icon
              >
            </template>
            O tipo de venda Sob Demanda tem como requisito a contratação do
            módulo Venda Mais, para mais informações entre em contato com o
            setor comercial.
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <FormField
      label="Código agrupador de embalagem"
      placeholder="(Opcional)"
      v-model="form.agrupador_embalagem"
    />
    <FormField
      label="Descrição curta sobre a embalagem"
      placeholder="(Opcional)"
      v-model="form.descricao_embalagem"
    />
    <FormField
      label="Quantidade de subitens na embalagem"
      v-model="form.quantidade_subitem_embalagem"
      :money="moneyMask"
    />
    <FormField
      label="Máximo disponível venda"
      v-model="form.maximo_disponivel"
      :money="moneyMask"
      help="Informe qual quantidade máxima deste produto disponível a venda."
    />
    <div v-if="enableCommerce">
      <FormSwitch
        v-model="form.permitir_alteracao_campos_erp"
        label="Permitir que a integração modifique a quantidade máxima de venda"
        val="maximo_disponivel"
      />
      <FormField
        v-if="enableMaxLimitControl"
        label="Máximo permitido por pedido"
        v-model="form.max_por_pedido"
        :money="form.max_por_pedido ? moneyMask : null"
        help="Informe qual quantidade máxima deste produto dentro do pedido."
      />
      <FormSwitch
        v-if="enableMaxLimitControl"
        v-model="form.permitir_alteracao_campos_erp"
        label="Permitir que a integração modifique o máximo por pedido"
        val="max_por_pedido"
      />
      <FormField
        v-if="enableMinLimitControl"
        label="Mínimo permitido por pedido"
        v-model="form.min_por_pedido"
        :money="form.min_por_pedido ? moneyMask : null"
        help="Informe qual quantidade mínima deste produto dentro do pedido."
      />
      <FormSwitch
        v-if="enableMinLimitControl"
        v-model="form.permitir_alteracao_campos_erp"
        label="Permitir que a integração modifique o mínimo por pedido"
        val="min_por_pedido"
      />
    </div>
    <p>
      <b>Detalhes do produto</b> (<a
        href="https://pt.wikipedia.org/wiki/Markdown"
        target="_blank"
        >dicas de formatação</a
      >)
    </p>
    <FormMarkdown v-model="form.descricao_longa" />
    <FormSwitch
      v-model="form.permite_alteracao_erp"
      label="Permitir que a integração modifique dados do produto"
    />
    <small>
      Quando a flag acima é desmarcada, as alterações nos campos de Nome,
      Descrição Curta de Embalagem, Detalhes do Produto, Unidade de Venda e
      Unidade de Venda Multiplos não são atualizadas pela integração
    </small>
    <LineDivider><h4>SEO</h4></LineDivider>
    <FormField
      label="URL amigável"
      v-model="form.slug"
      help="URL usada para acessar o detalhamento do produto. Dê preferência a URLs curtas e diretas que facilitem a visualização e digitação"
      v-stringscaped
    />
    <FormField
      label="Descrição curta"
      v-model="form.metatags_seo.description"
      help="Máximo 160 caracteres sobre o produto, ela é utilizada por ferramentas de busca online do produto."
      counter
      maxLength="160"
    />
    <FormField
      label="Palavras-chave"
      v-model="form.metatags_seo.keywords"
      help="Máximo 160 caracteres. Separadas por vírgula. Estas palavras são utilizadas pelas ferramentas de busca online do produto."
      counter
      maxLength="160"
    />
    <LineDivider><h4>Imagens do produto</h4></LineDivider>
    <DragDropUploader
      v-model="form.images"
      :upload-url="uploadUrl"
      :upload-multiple="true"
      :loading="imageLoading"
      initial-icon-class="fas fa-cloud-upload-alt"
      @remove="removeImage"
    >
    </DragDropUploader>
    <LineDivider><h4>Categorias do produto</h4></LineDivider>
    <ArvoreCategorias v-model="form.setores" />

    <LineDivider><h4>Tags de produto</h4></LineDivider>
    <FormTags
      placeholder="Pesquise e adicione tags"
      v-model="form.tag_pesquisa"
      :endpoint="endpointTags"
      keyid="idtagpesquisa"
      keyvalue="valor"
      :search-with-spaces="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.text }}</p>
          <small v-if="item.small">Cod.: {{ item.descricao }}</small>
          <small v-if="item.agrupadores"
            >Agr.:{{ item.agrupadores.join('|') }}</small
          >
        </div>
      </template>
    </FormTags>
    <LineDivider>
      <h4>Segmento de clientes</h4>
    </LineDivider>
    <FormTags
      placeholder="Pesquise e adicione segmento de clientes"
      v-model="form.segmentoscliente"
      :endpoint="endpointSegmentoCliente"
      keyid="idsegmentocliente"
      keyvalue="nome"
      :search-with-spaces="true"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.nome }}</p>
        </div>
      </template>
    </FormTags>
    <LineDivider><h4>Características do produto</h4></LineDivider>
    <ProdutoCaracteristicas
      v-model="form.caracteristicas"
      :validation="$v.form.caracteristicas"
    />
    <div v-if="enableCommerce">
      <LineDivider><h4>Formas de pagamento</h4></LineDivider>
      <ProdutoFormasPagamento
        v-model="form.formasPagamentoCustomizadas"
        :validation="$v.form.formasPagamentoCustomizadas"
      />
    </div>
    <LineDivider><h4>Vitrine e carrosel</h4></LineDivider>
    <FormTags
      placeholder="Pesquise pelo titulo da vitrine ou carrossel"
      v-model="form.carrossel"
      :endpoint="endpointCarrossel"
      keyid="idcarrossel"
      keyvalue="titulo"
    >
      <template v-slot:item="{ item }">
        <div class="d-flex flex-column align-start">
          <p style="margin-bottom:0!important">{{ item.titulo }}</p>
          <small
            >Tipo:
            {{
              item.tipo_conteudo === 'STATIC_IMAGES'
                ? 'Carrossel de imagens'
                : 'Vitrine de produtos'
            }}</small
          >
          <small>Dispositivos: {{ item.device.join(',') }}</small>
        </div>
      </template>
    </FormTags>
    <div v-if="enableCommerce">
      <LineDivider><h4>Dados de transporte</h4></LineDivider>
      <FormField label="Altura (m)" v-model="form.altura" :money="moneyMask" />
      <FormField
        label="Largura (m)"
        v-model="form.largura"
        :money="moneyMask"
      />
      <FormField
        label="Profundidade (m)"
        v-model="form.comprimento"
        :money="moneyMask"
      />
      <FormField label="Peso (kg)" v-model="form.peso" :money="moneyMask" />
      <FormField label="Diâmetro" v-model="form.diametro" :money="moneyMask" />

      <LineDivider><h4>Embalagem de presente</h4></LineDivider>
      <FormCheckbox
        label="Permite usar embalagem"
        v-model="form.permite_embalagem"
      />
      <FormField
        v-if="form.permite_embalagem"
        label="Preço da embalagem"
        v-model="form.preco_embalagem"
        :money="moneyMask"
      />
      <LineDivider><h4>Visualização</h4></LineDivider>
      <FormCheckbox
        label="Ocultar da busca principal"
        v-model="form.ocultar_busca_principal"
      />
    </div>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import ArvoreCategorias from './ArvoreCategoria'
import LineDivider from '@/components/LineDivider'
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'
import {
  PRODUTO_CREATE,
  PRODUTO_LOAD,
  PRODUTO_UPDATE,
  PRODUTO_LOAD_BY_COD_BARRAS,
  PRODUTO_LOAD_IMAGES,
  PRODUTO_REMOVE_IMAGE
} from '@/store/actions/produto'
import FormSwitch from '@/components/form/FormSwitch'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormTags from '@/components/form/FormTags'
import { mapGetters } from 'vuex'
import DragDropUploader from '@/components/Drag-drop-uploader'
import FormMarkdown from '@/components/form/FormMarkdown'
import ProdutoCaracteristicas from './ProdutoFormCaracteristica'
import ProdutoFormasPagamento from './ProdutoFormFormasPagamento'
import * as _ from 'lodash'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'ProdutoForm',
  components: {
    SidePopup,
    FormButton,
    ArvoreCategorias,
    LineDivider,
    FormField,
    FormSwitch,
    FormCheckbox,
    FormTags,
    DragDropUploader,
    FormMarkdown,
    ProdutoCaracteristicas,
    ProdutoFormasPagamento
  },
  data: () => ({
    mode: 'add',
    produto_principal: {},
    form: {
      id_produto_principal: undefined,
      images: [],
      codigobarras: null,
      id_produto_erp: '',
      descricao: '',
      descricoes_adicionais: [],
      unidade: 'UN',
      idProduto: null,
      setores: [],
      privado: false,
      label_unidade: '',
      label_unidade_multiplo: '',
      multiplo_incremento: 0,
      descricao_embalagem: '',
      descricao_longa: '',
      imagem_principal: '',
      disponivel: false,
      ativo: true,
      permite_alteracao_erp: true,
      permitir_alteracao_campos_erp: [],
      ignorar_alteracao_erp: [],
      granularidade: null,
      hasLogIntegracao: false,
      peso: 0,
      altura: 0,
      largura: 0,
      diametro: 0,
      comprimento: 0,
      previewImage: '',
      preco_embalagem: null,
      permite_embalagem: false,
      ocultar_busca_principal: false,
      metatags_seo: {
        description: '',
        keywords: ''
      },
      slug: '',
      quantidade_subitem_embalagem: '',
      tag: ['AVULSO'],
      tag_pesquisa: [],
      caracteristicas: [],
      formasPagamentoCustomizadas: [],
      camposIgnoraveisIntegracao: [
        'granularidade',
        'max_por_pedido',
        'min_por_pedido',
        'maximo_disponivel',
        'itens_relacionados'
      ],
      maximo_disponivel: null,
      max_por_pedido: null,
      min_por_pedido: null,
      carrossel: [],
      segmentoscliente: []
    },
    categorias: [],
    moneyMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 3,
      masked: false
    },
    tags_esquisa: [],
    isLoading: false,
    imageLoading: false
  }),
  validations: {
    form: {
      descricao: { required },
      codigobarras: {
        numeric,
        required: requiredIf(function(model) {
          return !model.id_produto_erp
        })
      },
      id_produto_erp: {
        required: requiredIf(function(model) {
          return !model.codigobarras
        })
      },
      caracteristicas: {
        $each: {
          idcaracteristica: { required },
          descricao: { required }
        }
      },
      formasPagamentoCustomizadas: {
        $each: {
          idformapagto: { required },
          fator: { required }
        }
      }
    }
  },
  methods: {
    getDataFormasPagamentosCustomizadas(formas) {
      const formasPagamento = []
      if (formas) {
        const _formas = Object.values(formas)
        if (_formas && _formas.length) {
          _formas.forEach(ff => {
            formasPagamento.push({
              ...ff,
              id: `${ff.idformapagto}-${Math.random() * 1000}`
            })
          })
        }
      }
      return formasPagamento
    },
    getDataSubmitFormasPagamento(formas) {
      return (
        (formas &&
          formas.map(f => {
            return { ...f, fator: Number(f.fator) }
          })) ||
        null
      )
    },
    handleEditNomeAlternativo(position, newString) {
      const novaLista = [...this.form.descricoes_adicionais]
      novaLista[position] = newString
      this.form.descricoes_adicionais = novaLista
    },
    handleRemoverNomeAlternativo(position) {
      const novaLista = [...this.form.descricoes_adicionais].filter(
        (v, k) => k !== position
      )
      this.form.descricoes_adicionais = novaLista
    },
    getData(idproduto, idcadastroextraproduto) {
      this.isLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD, { idproduto, idcadastroextraproduto })
        .then(produto => {
          this.produto_principal = produto.produto_principal
          this.form = {
            ...this.form,
            ...produto,
            descricoes_adicionais: produto.descricoes_adicionais || [],
            unidade: produto.tipounidade,
            granularidade: this.prepareNumberDecimalToString(
              produto.granularidade
            ),
            peso: this.prepareNumberDecimalToString(produto.peso),
            altura: this.prepareNumberDecimalToString(produto.altura),
            largura: this.prepareNumberDecimalToString(produto.largura),
            diametro: this.prepareNumberDecimalToString(produto.diametro),
            comprimento: this.prepareNumberDecimalToString(produto.comprimento),
            multiplo_incremento: this.prepareNumberDecimalToString(
              produto.multiplo_incremento
            ),
            preco_embalagem: this.prepareNumberDecimalToString(
              produto.preco_embalagem
            ),
            quantidade_subitem_embalagem: this.prepareNumberDecimalToString(
              produto.quantidade_subitem_embalagem
            ),
            privado: false,
            metatags_seo: {
              description: _.get(produto, 'metatags_seo.description', ''),
              keywords: _.get(produto, 'metatags_seo.keywords', '')
            },
            tag_pesquisa: produto.tag_pesquisa || [],
            setores: (produto.setores || []).map(item => item.idsetor),
            caracteristicas: (produto.caracteristicas || []).map(item => ({
              ...item,
              id: `${item.idcaracteristica}-${Math.random() * 1000}`
            })),
            formasPagamentoCustomizadas: this.getDataFormasPagamentosCustomizadas(
              produto.formas_pagto_customizadas
            ),
            images: [],
            descricao_longa: produto.descricao_longa || '',
            permitir_alteracao_campos_erp: _.difference(
              this.form.camposIgnoraveisIntegracao,
              produto.ignorar_alteracao_erp
            ),
            maximo_disponivel: produto.maximo_disponivel
              ? this.prepareNumberDecimalToString(produto.maximo_disponivel)
              : null,
            max_por_pedido: produto.max_por_pedido
              ? this.prepareNumberDecimalToString(produto.max_por_pedido)
              : null,
            min_por_pedido: produto.min_por_pedido
              ? this.prepareNumberDecimalToString(produto.min_por_pedido)
              : null,
            carrossel: produto.carrossel || [],
            segmentoscliente: produto.segmentos_cliente
          }
          this.loadImages(idcadastroextraproduto)
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      if (
        this.form.tag.includes('SOB_DEMANDA') &&
        (this.form.tag.includes('RECORRENCIA') ||
          this.form.tag.includes('AVULSO'))
      ) {
        this.$vueOnToast.pop(
          'error',
          'O produto não pode ser do tipo venda Avulso/Recorrência e ao mesmo tempo Sob Demanda!'
        )
        return
      }
      this.isLoading = true
      let produto = {
        ...this.form,
        id_produto_principal: this.produto_principal?.idcadastroextraproduto,
        formasPagamentoCustomizadas: this.getDataSubmitFormasPagamento(
          this.form.formasPagamentoCustomizadas
        ),
        //descricoes_adicionais: this.form.descricoes_adicionais.split(','),
        descricoes_adicionais: this.form.descricoes_adicionais.filter(
          f => !!f.trim()
        ),
        idsetor: this.form.setores,
        multiplo_incremento: Math.abs(
          +this.prepareNumberDecimal(this.form.multiplo_incremento)
        ),
        id_imagem_produto: this.productImageId,
        granularidade: Math.abs(
          +this.prepareNumberDecimal(this.form.granularidade)
        ),
        peso: Math.abs(+this.prepareNumberDecimal(this.form.peso)),
        altura: Math.abs(+this.prepareNumberDecimal(this.form.altura)),
        largura: Math.abs(+this.prepareNumberDecimal(this.form.largura)),
        comprimento: Math.abs(
          +this.prepareNumberDecimal(this.form.comprimento)
        ),
        diametro: Math.abs(+this.prepareNumberDecimal(this.form.diametro)),
        quantidade_subitem_embalagem: Math.abs(
          +this.prepareNumberDecimal(this.form.quantidade_subitem_embalagem)
        ),
        preco_embalagem: this.form.permite_embalagem
          ? Math.abs(+this.prepareNumberDecimal(this.form.preco_embalagem))
          : null,
        ocultar_busca_principal: this.form.ocultar_busca_principal,
        tipounidade: this.form.unidade,
        maximo_disponivel: this.form.maximo_disponivel
          ? this.prepareNumberDecimal(this.form.maximo_disponivel)
          : null,
        max_por_pedido: this.form.max_por_pedido
          ? this.prepareNumberDecimal(this.form.max_por_pedido)
          : null,
        min_por_pedido: this.form.min_por_pedido
          ? this.prepareNumberDecimal(this.form.min_por_pedido)
          : null,
        tem_imagem: _.get(this.form, 'images', []).length ? true : false
      }
      if (
        !produto.id_produto_erp &&
        produto.codigobarras &&
        !isNaN(produto.codigobarras)
      ) {
        produto.id_produto_erp = produto.codigobarras
      }
      if (this.mode == 'edit' && produto.idproduto) {
        this.$store
          .dispatch(PRODUTO_UPDATE, produto)
          .then(() => {
            this.$vueOnToast.pop('success', 'Produto atualizado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(PRODUTO_CREATE, produto)
          .then(() => {
            this.$vueOnToast.pop('success', 'Produto criado com sucesso')
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    },
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/produto')
      }
    },
    prepareNumberDecimal(number) {
      return number
        .toString()
        .replace(',', '.')
        .replace(/\s/g, '')
    },
    prepareNumberDecimalToString(number) {
      return `${(Number(number) || 0).toFixed(3)}`.replace('.', ',')
    },
    pesquisarCodigoBarrasExistente() {
      if (!this.form.codigobarras) return
      this.isLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD_BY_COD_BARRAS, {
          codigobarras: this.form.codigobarras
        })
        .then(produto => {
          if (produto && produto.idproduto) {
            if (produto.idcadastroextraproduto) {
              this.mode = 'edit'
              this.loadImages(produto.idcadastroextraproduto)
            }
            this.form = {
              ...this.form,
              ...produto,
              unidade: produto.tipounidade,
              granularidade: this.prepareNumberDecimalToString(
                produto.granularidade
              ),
              peso: this.prepareNumberDecimalToString(produto.peso),
              altura: this.prepareNumberDecimalToString(produto.altura),
              largura: this.prepareNumberDecimalToString(produto.largura),
              diametro: this.prepareNumberDecimalToString(produto.diametro),
              comprimento: this.prepareNumberDecimalToString(
                produto.comprimento
              ),
              multiplo_incremento: this.prepareNumberDecimalToString(
                produto.multiplo_incremento
              ),
              quantidade_subitem_embalagem: this.prepareNumberDecimalToString(
                produto.quantidade_subitem_embalagem
              ),
              privado: false,
              metatags_seo: {
                description: _.get(produto, 'metatags_seo.description', ''),
                keywords: _.get(produto, 'metatags_seo.keywords', '')
              },
              tag_pesquisa: produto.tag_pesquisa || [],
              setores: (produto.setores || []).map(item => item.idsetor),
              caracteristicas: (produto.caracteristicas || []).map(item => ({
                ...item,
                id: `${item.idcaracteristica}-${Math.random() * 1000}`
              })),
              formasPagamentoCustomizadas: this.getDataFormasPagamentosCustomizadas(
                produto.formas_pagto_customizadas
              ),
              images: [],
              descricao_longa: produto.descricao_longa || '',
              carrossel: produto.carrossel || []
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    resetForm() {
      ;(this.form = {
        codigobarras: null,
        id_produto_erp: '',
        descricao: '',
        unidade: 'UN',
        idProduto: null,
        setores: [],
        privado: false,
        label_unidade: '',
        label_unidade_multiplo: '',
        multiplo_incremento: 0,
        descricao_embalagem: '',
        descricao_longa: '',
        imagem_principal: '',
        disponivel: false,
        ativo: true,
        permite_alteracao_erp: true,
        ignorar_alteracao_erp: [],
        granularidade: null,
        hasLogIntegracao: false,
        peso: 0,
        altura: 0,
        largura: 0,
        diametro: 0,
        comprimento: 0,
        previewImage: '',
        permite_embalagem: false,
        ocultar_busca_principal: false,
        preco_embalagem: null,
        images: [],
        metatags_seo: {
          description: '',
          keywords: ''
        },
        slug: '',
        quantidade_subitem_embalagem: '',
        tag: ['AVULSO'],
        tag_pesquisa: [],
        caracteristicas: [],
        formasPagamentoCustomizadas: [],
        maximo_disponivel: null,
        max_por_pedido: null,
        min_por_pedido: null,
        carrossel: []
      }),
        (this.produto_principal = {})
    },
    loadImages(idcadastroextraproduto) {
      this.imageLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD_IMAGES, { idcadastroextraproduto })
        .then(respImg => {
          this.imageLoading = false
          this.form.images = _.get(respImg, 'imagens', [])
        })
        .catch(() => (this.imageLoading = false))
    },
    removeImage(imagem) {
      this.$store
        .dispatch(PRODUTO_REMOVE_IMAGE, {
          idcadastroextraproduto: this.productImageId,
          imagem
        })
        .then(() => {
          setTimeout(() => this.loadImages(this.productImageId), 500)
        })
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    title() {
      return this.mode == 'edit' ? 'Editar produto' : 'Novo produto'
    },
    endpointTags() {
      return `/api/v4/tag-pesquisa/${this.getFornecedorId}`
    },
    endpointProdutos() {
      return `/api/v1/produtos/s2format-simple?idfornecedor=${this.getFornecedorId}`
    },
    endpointCarrossel() {
      return `/api/v4/carrossel/select/${this.getFornecedorId}?local=page:product-details`
    },
    uploadUrl() {
      return `/api/web/internal/v2/produto/${this.getFornecedorId}/${this.productImageId}/imagem`
    },
    productImageId() {
      let idcadastroextraproduto = 'novo-produto'
      if (this.form.idcadastroextraproduto) {
        idcadastroextraproduto = this.form.idcadastroextraproduto
      }
      return idcadastroextraproduto
    },
    disabledCodBarras() {
      return !this.form.privado && this.form.idproduto ? true : false
    },
    enableMaxLimitControl() {
      return this.getFornecedorTag['enable-max-limit-control']
    },
    enableMinLimitControl() {
      return this.getFornecedorTag['enable-min-limit-control']
    },
    enableCommerce() {
      return this.showComponente([
        ModulesEnum.WEB_COMMERCE,
        ModulesEnum.COMMERCE_APP,
        ModulesEnum.ORCAMENTO_APP,
        ModulesEnum.ORCAMENTO_WEB
      ])
    },
    enableVendaMais() {
      return this.showComponente([
        ModulesEnum.VENDAMAIS_WEB,
        ModulesEnum.VENDAMAIS_APP
      ])
    },
    endpointSegmentoCliente() {
      return `/api/v4/segmento-cliente/${this.getFornecedorId}`
    }
  },
  created() {
    let { idproduto, idcadastroextraproduto } = this.$route.params
    if (idproduto !== 'novo') {
      this.mode = 'edit'
      this.getData(idproduto, idcadastroextraproduto)
    } else {
      this.editable = true
    }
  },
  watch: {
    'form.permitir_alteracao_campos_erp': {
      deep: true,
      handler(newVal) {
        this.form.ignorar_alteracao_erp = _.difference(
          this.form.camposIgnoraveisIntegracao,
          newVal
        )
      }
    }
  }
}
</script>

<style lang="scss">
.lista-nomes-alternativos {
  margin-left: 5%;
  margin-right: 15%;
  margin-bottom: 2em;
  padding: 1em;
}
</style>
