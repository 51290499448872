<template>
  <div>
    <v-row v-for="item in formasPagamento" :key="item.id">
      <v-col class="d-flex justify-center" cols="12" xs="12" sm="1" md="1">
        <FormButton icon type="perigo" @click="remove(item.id)"
          ><v-icon size="18">fas fa-times</v-icon></FormButton
        >
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="5">
        <FormAutocomplete
          label="Forma de pagamento"
          v-model="item.idformapagto"
          :options="options"
          :validation="getValidationField(item.id, 'idformapagto')"
          class="form-inline-item"
        />
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6">
        <FormField
          class="form-inline-item"
          label="Fator de preço adicional"
          @blur="setDemaisDados(item)"
          :validation="getValidationField(item.id, 'fator')"
          typeField="number"
          step="any"
          v-model="item.fator"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <FormButton type="primario" @click="add" text="Novo fator de preço" />
    </v-row>
  </div>
</template>

<script>
import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { mapGetters } from 'vuex'

import * as _ from 'lodash'
export default {
  name: 'ProdutoFormFormasPagamento',
  components: { FormAutocomplete, FormField, FormButton },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: () => ({
    formasPagamento: [
      {
        id: Math.random() * 10000,
        idformapagto: '',
        descricao: '',
        id_erp: null,
        fator: ''
      }
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    options() {
      return (this.$store.getters.getFormasPagamento || []).map(item => ({
        id_erp: item.id_payment_method_erp,
        descricao: item.description,
        value: item.idformapagto,
        text: item.description,
        fator: null
      }))
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.formasPagamento = newVal
      }
    }
  },
  created() {
    if (this.value) {
      this.formasPagamento = this.value
    }
  },
  methods: {
    add() {
      this.formasPagamento.push({
        id: Math.random() * 10000,
        idformapagto: '',
        descricao: '',
        id_erp: null,
        fator: ''
      })
    },
    remove(id) {
      this.formasPagamento = this.formasPagamento.filter(item => item.id != id)
      this.onChange()
    },
    setDemaisDados(item) {
      if (item) {
        const fp = this.options.find(o => o.value === item.idformapagto)
        item.id_erp = fp ? fp.id_erp : null
        item.descricao = fp ? fp.descricao : ''
      }
    },
    onChange() {
      this.$emit('input', this.formasPagamento)
      this.$emit('change', this.formasPagamento)
    },
    getValidationField(id, columns) {
      if (this.validation && this.validation.$each) {
        for (const i in this.validation.$each.$iter) {
          let val = this.validation.$each.$iter[i]
          if (_.get(val, '$model.id', '') === id) {
            return _.get(val, columns, null)
          }
        }
      }
      return null
    }
  }
}
</script>
